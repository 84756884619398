.mainpagetitle {
  font-family: 'Poppins';
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
}
.login-background {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: fixed;
}
.main-login-page {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  align-items: center;
}
.login-section {
  width: 41%;
  display: flex;
  justify-content: center;
}
.login-div {
  position: relative;
  width: 70%;
  background-color: white;
  padding: 30px 40px;
}
.login-vector-up {
  position: absolute;
  top: -60px;
  left: -115px;
  width: 180px;
  height: 180px;
}
.login-vector-down {
  position: absolute;
  bottom: -110px;
  right: -50px;
  width: 180px;
  height: 180px;
}
.login-logo-section {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-logo-image {
  width: 190px;
  height: 45px;
}
.title-login {
  font-weight: 600;
  color: #212121;
  margin-top: 15px;
  margin-left: 15px;
}
.login-main-form {
  margin-top: 25px;
}
.main-login-form .MuiOutlinedInput-notchedOutline {
  border: 2px solid #d2d2d2 !important;
  border-radius: 14px !important;
}
.main-login-form .Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #9f9f9f !important;
  border-radius: 14px !important;
}
.main-login-form .MuiInputLabel-outlined {
  color: rgb(52, 52, 52) !important;
  font-weight: 600 !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
}
.main-login-form .MuiOutlinedInput-input {
  color: rgb(52, 52, 52) !important;
  font-weight: 600 !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
  font-size: 16px !important;
  line-height: 18px !important;
  padding: 20px 5px !important;
}
.main-login-form .MuiOutlinedInput-input::placeholder {
  color: rgb(52, 52, 52) !important;
}
.main-login-form {
  font-weight: 600 !important;
  font-family: 'Poppins' !important;
  font-style: normal !important;
}
.loginform-field-icons {
  width: 25px;
  height: 25px;
  fill: rgb(52, 52, 52);
}
.login-space-divider {
  margin-top: 25px;
}
.signin-button-main {
  margin-top: 25px;
}
.signin-button-login-page {
  padding: 20px;
  width: 100%;
  background: #000000;
  border: 1px solid black;
  border-radius: 14px;
  color: #ffffff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.signin-button-login-page:hover {
  background: #d6d6d6;
  border: 1px solid black;
  color: black;
}
.signin-button-login-page:focus {
  background: #000000;
  border: 1px solid black;
  color: #ffffff;
}
@media only screen and (min-width: 280px) and (max-width: 500px) {
  .mainpagetitle {
    font-size: 16px;
    line-height: 20px;
  }
  .login-section {
    width: 100%;
  }
  .login-div {
    width: 80%;
    padding: 20px 15px;
  }
  .login-vector-up {
    width: 150px;
    height: 150px;
    top: -56px;
    left: -84px;
  }
  .login-vector-down {
    display: none;
  }
  .login-logo-image {
    width: 150px;
    height: 35px;
  }
  .title-login {
    margin-top: 10px;
  }
  .main-login-form .MuiOutlinedInput-input {
    font-size: 14px !important;
    line-height: 16px !important;
    padding: 15px 5px !important;
  }
  .loginform-field-icons {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 501px) and (max-width: 749px) {
  .mainpagetitle {
    font-size: 16px;
    line-height: 20px;
  }
  .login-section {
    width: 90%;
  }
  .login-div {
    width: 80%;
    padding: 20px 15px;
  }
  .login-vector-up {
    width: 150px;
    height: 150px;
    top: -56px;
    left: -84px;
  }
  .login-vector-down {
    bottom: -40px;
    right: 0;
    width: 90px;
    height: 90px;
  }
  .login-logo-image {
    width: 150px;
    height: 35px;
  }
  .title-login {
    margin-top: 10px;
  }
  .main-login-form .MuiOutlinedInput-input {
    font-size: 14px !important;
    line-height: 16px !important;
    padding: 15px 5px !important;
  }
  .loginform-field-icons {
    width: 20px;
    height: 20px;
  }
}
@media only screen and (min-width: 750px) and (max-width: 1024px) {
  .login-section {
    width: 70%;
  }
  .login-div {
    width: 80%;
    padding: 20px 15px;
  }
  .login-vector-up {
    width: 170px;
    height: 170px;
    top: -56px;
    left: -84px;
  }
  .login-vector-down {
    bottom: -70px;
    right: 0;
    width: 90px;
    height: 90px;
  }
  .login-logo-image {
    width: 170px;
    height: 40px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1255px) {
  .login-section {
    width: 60%;
  }
  .login-div {
    width: 80%;
    padding: 20px 15px;
  }
  .login-vector-up {
    width: 170px;
    height: 170px;
    top: -50px;
    left: -84px;
  }
  .login-vector-down {
    bottom: -60px;
    right: 0;
    width: 120px;
    height: 120px;
  }
  .login-logo-image {
    width: 180px;
    height: 40px;
  }
}
@media only screen and (min-width: 1256px) and (max-width: 1439px) {
  .login-section {
    width: 55%;
  }
  .login-div {
    width: 80%;
    padding: 20px 15px;
  }
  .login-vector-up {
    width: 170px;
    height: 170px;
    top: -50px;
    left: -84px;
  }
  .login-vector-down {
    bottom: -60px;
    right: 0;
    width: 120px;
    height: 120px;
  }
  .login-logo-image {
    width: 190px;
    height: 45px;
  }
}
